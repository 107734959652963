import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AltLayout from '../../components/altLayout';
import Lock from '../../components/images/locked';

const Login = (props) => {
  const [isDarkThemeActive, setDarkThemeActive] = useState(false);
  const [error, setError] = useState(null);

  const toggleActiveTheme = () => {
    setDarkThemeActive((prevState) => ({ isDarkThemeActive: !prevState.isDarkThemeActive }));

    window.localStorage.setItem('isDarkThemeActive', JSON.stringify(!isDarkThemeActive));
  };

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 3000);
  }, [error]);

  return (
    <>
      <AltLayout>
        <div className="rightContainer">
          <div className="lockCont">
            <Lock size="150px" color="#d2d2d2" />
            <Title> This is a paid module. </Title>
            <div className="btn">
              <a
                href="https://dipakkr.gumroad.com/l/pricingforpm"
                target="_blank"
                className="form-control btn btn-primary"
              >
                Unlock now
              </a>
              <div style={{ marginTop: '1rem' }}>
                <a href="/login"> Already bought ? Click here to Login. </a>
              </div>
            </div>
          </div>
        </div>
      </AltLayout>

      {/* <Footer position="fixed" />  */}
    </>
  );
};

export default Login;

const Title = styled.h2`
  font-weight: normal;
  color: #2a2a29;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 2rem;
`;